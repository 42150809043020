import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/appHome.vue'
import Procurement from '../views/Procurement'
import Travel from '../views/Travel'
import Time from '../views/Time'
import Finance from '../views/Finance'
import HR from '../views/HR'
import UserAccess from '../views/UserAccess'
import Logout from '../views/Logout'
import FAQ from '../views/FAQ'
import NullUser from '../views/nullUser'
import Admin from '../views/Admin'
//import Auth from '@okta/okta-vue'
import { default as msalPlugin } from 'vue-msal-browser'
import msal from '../plugins/msal'

Vue.use(VueRouter)

  const routes = [
    {
      path: "/implicit/callback",
      headers: { 
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'content-type, Authorization, Cache-Control',
        'Cache-Control': 'private, no-store',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff'
      },
      //component: Auth.handleCallback(),
      component:{},
    },
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: { requiresAuth: true },
    },
    {
      path: "/procurement",
      name: "Procurement",
      component: Procurement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/nullUser",
      name: "NullUser",
      component: NullUser,
      meta: { requiresAuth: true },
    },
    {
      path: "/time",
      name: "Time",
      component: Time,
      meta: { requiresAuth: true },
    },
    {
      path: "/travel",
      name: "Travel",
      component: Travel,
      meta: { requiresAuth: true },
    },
    {
      path: "/finance",
      name: "Finance",
      component: Finance,
      meta: { requiresAuth: true },
    },
    {
      path: "/hr",
      name: "HR",
      component: HR,
      meta: { requiresAuth: true },
    },
    {
      path: "/admin",
      name: "admin",
      component: Admin,
      meta: { requiresAuth: true }
    },
    {
      path: "/dxcit",
      name: "UserAccess",
      component: UserAccess,
      meta: { requiresAuth: true },
    },
    {
      path: "/logout",
      name: "Logout",
      component: Logout,
    },
    {
      path: "/faq",
      name: "FAQ",
      component: FAQ,
    },
  ];

Vue.use(msalPlugin,msal);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  msalPlugin,
  msal,
  routes
})


//router.beforeEach(Vue.prototype.$auth.authRedirectGuard())

export default router
